<template>
    <div>
        <div>
            <Menu/>
        </div>

        <div>

        </div>

        <div>
            <Bottom/>
        </div>
    </div>
</template>

<script>
    import Menu from "../../components/Menu";
    import Bottom from "../../components/Bottom";
    import toolUtils from "../../tools/toolUtils";
    import { mixin1 } from "../../tools/mixins";
    import * as metaMaskTools from "../../tools/chain";
    import transfer from "../../tools/transfer";

    export default {
        name: "Index",
        components: { Menu, Bottom },
        mixins: [ mixin1 ],
        data() {
            return {}
        },
        watch: {},
        created() {
        },
        mounted() {
        },
        destroyed() {
        },
        methods: {}
    }
</script>

<style lang="less">

</style>
